import * as React from 'react'
import Layout from '../../components/layout'
import { Titulo } from '../../components/titulo'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'

export default function centroalumnos () {
    return (
        <Layout>
            <Titulo title="Centro de alumnos" />
            <Container>
                <Row className=" justify-content-center">
                    <Col md={3}>
                        <Card className="bg-dark text-center">
                            <Card.Body>
                                <StaticImage 
                                    src="../../images/centro-alumnos/presidenta.png" 
                                    alt="" 
                                    className="img-fluid img-rounded"
                                    height={220}
                                /> 
                                <h4>Isidora Vera</h4>
                                <h6>Presidenta</h6>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="bg-dark text-center">
                            <Card.Body>
                                <StaticImage 
                                    src="../../images/centro-alumnos/tesorera.png" 
                                    alt="" 
                                    className="img-fluid img-rounded"
                                    height={220}
                                /> 
                                <h4>Josefa Correa</h4>
                                <h6>Tesorera</h6>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={12} className="mb-5"></Col>
                </Row>
                
            </Container>
        </Layout>
    )
}